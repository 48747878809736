<template>
    <client-page>
        <shop-product-visual image="/images/shop/visual-sample.jpg" :title="category?.name" :breadcrumbs="breadcrumbs"></shop-product-visual>

        <page-section class="page-section--last">
            <v-container>
                <product-list-filter v-bind="{ filterings }"/>

                <v-row class="row--contents flex-xl-nowrap">
                    <v-col>
                        <!-- <div class="mb-20px">
                            <product-list-categories />
                        </div> -->
                        <div class="mb-20px">
                            <v-row align="center">
                                <!-- <v-col cols="12" md="">
                                    <tit-wrap-subtitle titAlign="text-center text-md-left" class="mb-0">
                                        <template slot="tit">
                                            <template v-if="$route.query.searchValue">
                                                {{ $route.query.searchValue }}
                                            </template>
                                            <template v-else-if="category?.name">
                                                {{ category?.name }}
                                            </template>
                                            <template v-else> 전체보기 </template>
                                        </template>
                                        <template slot="subTit">
                                            <template v-if="$route.query.searchValue"> 키워드검색 </template>
                                        </template>
                                    </tit-wrap-subtitle>
                                </v-col> -->
                                <v-col cols="12" md="auto" class="text-right">
                                    <div class="txt txt--lg txt--dark">{{ summary.totalCount.format() }} 개</div>
                                </v-col>
                                <v-spacer />
                                <v-col cols="12" md="auto">
                                    <v-row no-gutters align="center">
                                        <v-col cols="auto">
                                            <v-sheet width="96" class="txt txt--sm txt--dark">Sort By:</v-sheet>
                                        </v-col>
                                        <v-spacer />
                                        <v-col cols="auto">
                                            <product-list-sort-select />
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </div>

                        <!-- 상품 리스트 -->
                        <section class="product-lists">
                            <div class="product-lists-body">
                                <!-- <shop-product-items>
                                    <shop-product-item v-for="(product, index) in products" :key="index" :value="product" />
                                </shop-product-items> -->
                                <v-row class="row--lg">
                                    <template v-for="(product, index) in products">
                                        <v-col :key="index" cols="6" sm="4" lg="3">
                                            <shop-product-card :value="product" />
                                        </v-col>
                                    </template>
                                </v-row>

                                <div class="product-lists__pagination">
                                    <pagination-component :value="page" :count="pageCount" @input="(page) => $router.push({ query: { ...$route.query, page } })"></pagination-component>
                                </div>
                            </div>
                        </section>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
import { attrs_input } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapSubtitle from "@/components/client/dumb/tit-wrap-subtitle.vue";
import ShopProductItem from "@/components/client/shop/shop-product-item.vue";
import ShopProductItems from "@/components/client/shop/shop-product-items.vue";
import ProductListFilter from "@/components/client/shop/products/filter/product-list-filter.vue";
import PaginationComponent from "@/components/client/pagination-component.vue";
import ProductListSortSelect from "@/components/client/shop/products/list/product-list-sort-select.vue";
import ProductListCategories from "@/components/client/shop/products/list/product-list-categories.vue";

import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";
import ShopProductCard from "@/components/client/shop/shop-product-card.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        TitWrapSubtitle,
        ShopProductItem,
        ShopProductItems,
        ProductListFilter,
        PaginationComponent,
        ProductListSortSelect,
        ProductListCategories,
        ShopProductVisual,
        ShopProductCard,
    },
    metaInfo() {
        return this.seo;
    },
    data: () => ({
        products: [],
        filterings: [],

        limit: 24,
        summary: { totalCount: 0 },

        loading: false,

        attrs_input,

        breadcrumbs: [
            {
                text: "Products",
                disabled: false,
            },
        ],
    }),
    computed: {
        ...mapState(["categories"]),
        categories_children() {
            return this.categories.flatMap(({ children }) => children);
        },
        categories_grandChildren() {
            return this.categories_children.flatMap(({ children }) => children);
        },
        categories_granderChildren() {
            return this.categories_grandChildren.flatMap(({ children }) => children);
        },
        category() {
            const categoryCode = this.$route.query.category;
            if (categoryCode) return this.getCategory(this.categories, categoryCode) ?? this.getCategory(this.categories_children, categoryCode) ?? this.getCategory(this.categories_grandChildren, categoryCode) ?? this.getCategory(this.categories_granderChildren, categoryCode);
        },
        seo() {
            if (this.category?.seoEnabled) return this.category?.seo;
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            delete query.category;
            query._category = this.category?._id;
            if (!query._category) delete query._category;

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.init();
        },
    },
    methods: {
        async init() {
            this.products = [];
            this.summary.totalCount = 0;
            this.loading = false;

            this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                params = { ...params };
                var { summary, products, filterings } = await api.v1.shop.products.gets({
                    headers: { skip, limit },
                    params: { ...params, needFilters: true },
                });

                if (this.params?._category == params?._category) {
                    this.summary = summary;
                    this.products = products;
                    this.filterings = filterings;
                }
            } finally {
                this.loading = false;
            }
        },
        getCategory(categories, categoryCode) {
            return categories.find(({ code }) => code == categoryCode);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
</style>