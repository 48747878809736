<template>
    <client-page class="app--product">
        <page-section class="page-section--first page-section--last" v-if="product?._id">
            <!-- S: 상품 개요 -->
            <v-container>
                <div class="py-18px py-md-24px">
                    <v-row align="center">
                        <v-col cols="auto">
                            <v-breadcrumbs :items="breadcrumbs" class="pa-0">
                                <template v-slot:divider>
                                    <v-icon color="#333">mdi-chevron-right</v-icon>
                                </template>
                                <template v-slot:item="{ item }">
                                    <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
                                        <span class="font-size-12 font-weight-light">
                                            {{ item.text }}
                                        </span>
                                    </v-breadcrumbs-item>
                                </template>
                            </v-breadcrumbs>
                        </v-col>
                        <v-spacer />
                        <v-col cols="auto">
                            <info-share v-bind="{ product }" />
                        </v-col>
                    </v-row>
                </div>

                <v-row class="row--lg">
                    <v-col cols="12" md="6">
                        <product-view-imgs v-bind="{ product }" />
                    </v-col>
                    <v-col cols="12" md="6">
                        <product-view-info v-bind="{ product }">
                            <template #form>
                                <product-view-coupons v-bind="{ product }" />
                                <product-form-related-group-items v-bind="{ product }" />
                                <product-form-options v-model="carts" v-bind="{ product, noMandatoryCart }" />
                                <product-form-shipping v-model="shippingOption" v-bind="{ product }" />
                                <product-form-carts v-model="carts" />
                            </template>

                            <template #actions>
                                <product-form-price v-bind="{ carts }" />

                                <div class="btn-wrap">
                                    <v-row class="row--sm">
                                        <template v-if="product?.link?.enabled">
                                            <v-col cols="12">
                                                <product-view-link v-bind="{ ...btn_secondary, loading, product }" block class="v-size--xx-large" />
                                            </v-col>
                                        </template>
                                        <template v-else>
                                            <v-col cols="12" sm="">
                                                <v-btn v-bind="{ ...btn_secondary, loading }" block class="v-size--xx-large mobonCart rounded-xs" @click="addToCart">장바구니</v-btn>
                                            </v-col>
                                            <v-col cols="12" sm="">
                                                <v-btn v-bind="{ ...btn_primary, loading }" tile block class="primary v-size--xx-large mobonCart rounded-xs" @click="buyNow">바로구매</v-btn>
                                            </v-col>
                                            <v-col cols="auto">
                                                <v-btn v-bind="{ ...btn_primary, loading }" icon elevation="4" class="v-size--xx-large rounded-xs white">
                                                    <v-img max-width="24" src="/images/icon/icon-like.svg"></v-img>
                                                </v-btn>
                                            </v-col>
                                        </template>
                                    </v-row>
                                </div>
                            </template>

                            <template #foot>
                                <product-view-coupons v-bind="{ product }" class="d-lg-none" />
                                <!-- <div class="btn-wrap">
                            <naver-pay disabled button-key="E8FAC592-0582-4E64-B0A7-410385FFD5AA" :carts="carts" :_product="product?._id" />
                        </div> -->
                            </template>
                        </product-view-info>
                    </v-col>
                </v-row>
            </v-container>
            <!-- E: 상품 개요 -->

            <!-- S: 상품 상세 -->
            <v-container>
                <div class="product-details">
                    <product-view-nav v-bind="{ product, summary }">
                        <template #form>
                            <product-form-related-group-items v-bind="{ product }" />
                            <product-form-options v-model="carts" v-bind="{ product, noMandatoryCart }" />
                            <product-form-shipping v-model="shippingOption" v-bind="{ product }" />
                        </template>
                        <template #actions>
                            <product-form-carts v-model="carts" />
                            <product-form-price v-bind="{ carts }" />

                            <div class="btn-wrap">
                                <v-row class="row--sm">
                                    <template v-if="product?.link?.enabled">
                                        <v-col cols="12">
                                            <product-view-link v-bind="{ ...btn_secondary, loading, product }" block class="v-size--xx-large" />
                                        </v-col>
                                    </template>
                                    <template v-else>
                                        <v-col cols="12" sm="6">
                                            <v-btn v-bind="{ ...btn_secondary, ...$attrs, loading }" block class="v-size--xx-large mobonCart" @click="addToCart">장바구니</v-btn>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <v-btn v-bind="{ ...btn_primary, ...$attrs, loading }" block class="v-size--xx-large mobonCart" @click="buyNow">바로구매</v-btn>
                                        </v-col>
                                    </template>
                                </v-row>
                            </div>
                        </template>
                    </product-view-nav>

                    <product-view-reviews v-bind="{ product }" @setSummaryReviews="(count) => (summary.reviews = count)" />

                    <div id="detail" class="product-details__content mx-auto" style="max-width: 880px">
                        <section class="product-sub" v-html="product?.content"></section>
                        <iframe v-if="product.youtubeCode" width="560" height="315" :src="`https://www.youtube.com/embed/${product.youtubeCode}`" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen />
                    </div>

                    <product-view-inquires :product="product" @setSummaryInquires="(count) => (summary.inquires = count)" />

                    <product-view-others />
                </div>
            </v-container>
            <!-- E: 상품 상세 -->

            <!-- S: 모바일하단 고정영역 -->
            <product-view-mobile-bottom-sheet>
                <template #form>
                    <product-form-related-group-items v-bind="{ product }" />
                    <product-form-options v-model="carts" v-bind="{ product, noMandatoryCart }" />
                    <product-form-shipping v-model="shippingOption" v-bind="{ product }" />
                    <product-form-carts v-model="carts" />
                </template>

                <template #actions>
                    <product-form-price v-bind="{ carts }" />
                    <div class="btn-wrap">
                        <v-row class="row--sm">
                            <template v-if="product?.link?.enabled">
                                <v-col cols="12">
                                    <product-view-link v-bind="{ ...btn_secondary, loading, product }" block class="v-size--xx-large" />
                                </v-col>
                            </template>
                            <template v-else>
                                <v-col cols="6">
                                    <v-btn v-bind="{ ...btn_secondary, ...$attrs, loading }" block class="v-size--xx-large mobonCart" @click="addToCart">장바구니</v-btn>
                                </v-col>
                                <v-col cols="6">
                                    <v-btn v-bind="{ ...btn_primary, ...$attrs, loading }" block class="v-size--xx-large mobonCart" @click="buyNow"><span class="white--text">바로구매</span></v-btn>
                                </v-col>
                            </template>
                        </v-row>
                    </div>
                </template>
            </product-view-mobile-bottom-sheet>
            <!-- E: 모바일하단 고정영역 -->

            <product-form-modal-cart ref="modal-cart" />
        </page-section>
        <template v-else-if="!loading">
            <div class="text-center">
                <div class="mb-18px mb-md-24px">
                    <v-icon size="60px" color="primary">mdi-alert</v-icon>
                </div>
                <div class="tit tit--sm">상품을 찾을 수 없습니다.</div>
                <div class="txt txt--sm mt-8px">다른 상품을 확인해보세요.</div>

                <div class="btn-wrap btn-wrap--lg">
                    <v-row justify="center" class="row--sm">
                        <v-col cols="6" sm="auto">
                            <v-btn v-bind="{ ...btn_primary }" to="/shop" class="v-size--xx-large w-100 min-w-sm-200px">쇼핑 계속하기</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </template>
    </client-page>
</template>

<script>
import api from "@/api";
import { mapActions, mapMutations, mapState } from "vuex";
import { btn_primary, btn_secondary } from "@/assets/variables";
import { initProduct, initShopShippingOption, PRODUCT_TYPES } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";

// import NaverPay from "@/components/plugins/naver/naver-pay.vue";

import ProductViewNav from "@/components/client/shop/products/view/product-view-nav.vue";
import ProductViewImgs from "@/components/client/shop/products/view/product-view-imgs.vue";
import ProductViewInfo from "@/components/client/shop/products/view/product-view-info.vue";
import ProductViewLink from "@/components/client/shop/products/view/product-view-link.vue";
import ProductViewOthers from "@/components/client/shop/products/view/product-view-others.vue";
import ProductViewReviews from "@/components/client/shop/products/view/product-view-reviews.vue";
import ProductViewInquires from "@/components/client/shop/products/view/product-view-inquires.vue";
import ProductViewMobileBottomSheet from "@/components/client/shop/products/view/product-view-mobile-bottom-sheet.vue";

import ProductFormCarts from "@/components/client/shop/products/form/product-form-carts.vue";
import ProductFormPrice from "@/components/client/shop/products/form/product-form-price.vue";
import ProductFormOptions from "@/components/client/shop/products/form/product-form-options.vue";
import ProductFormShipping from "@/components/client/shop/products/form/product-form-shipping.vue";
import ProductFormModalCart from "@/components/client/shop/products/form/product-form-modal-cart.vue";
import ProductFormRelatedGroupItems from "@/components/client/shop/products/form/product-form-related-group-items.vue";

import ProductViewCoupons from "@/components/client/shop/products/view/product-view-coupons.vue";
import InfoShare from "@/components/client/shop/products/view/info/info-share.vue";

export default {
    components: {
        ClientPage,
        PageSection,

        // NaverPay,

        ProductViewNav,
        ProductViewImgs,
        ProductViewInfo,
        ProductViewLink,
        ProductViewOthers,
        ProductViewReviews,
        ProductViewInquires,
        ProductViewMobileBottomSheet,

        ProductFormCarts,
        ProductFormPrice,
        ProductFormOptions,
        ProductFormShipping,
        ProductFormModalCart,
        ProductFormRelatedGroupItems,

        ProductViewCoupons,
        InfoShare,
    },
    props: {
        _product: { type: String, default: null },
    },
    data: () => ({
        btn_primary,
        btn_secondary,

        product: initProduct(),

        summary: {
            reviews: 0,
            inquires: 0,
        },

        carts: [],

        shippingOption: initShopShippingOption(),

        loading: false,

        previousEnlipleTrackProductId: null,

        breadcrumbs: [
            {
                text: "Products",
                href: "/",
            },
            {
                text: "Sample",
                href: "/",
            },
        ],
    }),
    computed: {
        ...mapState(["watches"]),
        seo() {
            return this.product?.seo;
        },
        noMandatoryCart() {
            return !this.carts.some(({ _related, _supply }) => !_related && !_supply);
        },
    },
    metaInfo() {
        return { ...this.seo };
    },
    mounted() {
        this.init();
    },
    watch: {
        _product() {
            this.init();
        },
    },
    methods: {
        ...mapActions(["addToWatch"]),
        ...mapMutations(["setWatches"]),
        // ...mapActions("metaPixel", ["fbq"]),
        // ...mapActions("naverWcs", ["wcs_do_cnv"]),
        // ...mapActions("enlipleTracker", ["callMtmOnProductView"]),

        async init() {
            if (this.loading) return;
            else this.loading = true;

            this.summary = { reviews: 0, inquires: 0 };
            this.carts = [];
            this.shippingOption = initShopShippingOption();

            try {
                const { product } = await api.v1.shop.products.get({ _id: this._product, headers: { ["message-on-error"]: encodeURIComponent("상품을 찾을 수 없습니다.") } });
                this.product = product;

                const isVarietyBundle = product?.type == PRODUCT_TYPES.VARIETY_BUNDLE.value;
                const isProductBundle = product?.type == PRODUCT_TYPES.PRODUCT_BUNDLE.value;
                const isOptionEnabled = product?.optionsConf?.enabled;
                const isChoiceEnabled = product?.choiceesConf?.enabled;

                if (!isVarietyBundle) {
                    if (!isOptionEnabled && !isChoiceEnabled) {
                        if (isProductBundle) {
                            this.$set(this.carts, 0, {
                                _product: product._id,
                                product,
                                name: "본품",
                                price: product.price,
                                salePrice: product.salePrice,
                                discountPrice: product.discountPrice,
                                stock: product.stock__bundleds,
                                amount: 1,
                            });
                        } else {
                            this.$set(this.carts, 0, {
                                _product: product._id,
                                product,
                                name: "본품",
                                price: product.price,
                                salePrice: product.salePrice,
                                discountPrice: product.discountPrice,
                                stock: product.stock,
                                amount: 1,
                            });
                        }
                    }
                }

                const _watches = (this.$store?.state?.watches || []).map(({ _id }) => _id);
                const watches = (await api.v1.me.watches.gets({ params: { _watches } }))?.watches;
                this.setWatches({ watches });

                let watch = watches.find(({ _product }) => _product == product._id);

                if (!watch) {
                    watch = (await api.v1.me.watches.post({ _product: product._id }))?.watch;
                } else {
                    watch = (await api.v1.me.watches.put({ _id: watch._id, createdAt: Date.now() }))?.watch;
                }
                this.addToWatch([watch]);

                // const toEnlipleTrack = this.previousEnlipleTrackProductId != product._id;
                // if (toEnlipleTrack) {
                //     this.enlipleTracker();
                //     this.previousEnlipleTrackProductId = product._id;
                // }
            } finally {
                this.loading = false;
            }
        },

        ////////////////////////////////////////////////////////////////////////
        // 장바구니 담기
        ////////////////////////////////////////////////////////////////////////
        async addToCart() {
            if (this.loading) return;
            else this.loading = true;

            try {
                if (!this.carts.length) throw new Error("구매하실 상품을 선택해주세요");
                if (this.noMandatoryCart) {
                    throw new Error("상품이 선택되지 않았습니다");
                }
                if (!this.shippingOption?._id) throw new Error("배송받을 방법을 선택해주세요");

                var soldOut = this.carts.find((cart) => cart.stock == 0);
                if (soldOut) throw new Error("품절된 상품입니다");

                const { shippingOption } = this;
                const { carts } = await api.v1.me.carts.post({
                    carts: this.carts,
                    shippingOption,
                });
                this.$store.dispatch("addToCart", carts);

                this.$refs["modal-cart"].open();

                this.fbq({ type: "AddToCart" });
                this.wcs_do_cnv({ type: "3" });
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            } finally {
                this.loading = false;
            }
        },

        ////////////////////////////////////////////////////////////////////////
        // 바로구매
        ////////////////////////////////////////////////////////////////////////
        async buyNow() {
            if (this.loading) return;
            else this.loading = true;

            try {
                if (!this.carts.length) throw new Error("구매하실 상품을 선택해주세요");
                if (this.noMandatoryCart) {
                    throw new Error("상품이 선택되지 않았습니다");
                }
                if (!this.shippingOption?._id) throw new Error("배송받을 방법을 선택해주세요");

                var soldOut = this.carts.find((cart) => cart.stock == 0);
                if (soldOut) throw new Error("품절된 상품입니다");

                const { shippingOption } = this;
                const { carts } = await api.v1.me.carts.post(
                    {
                        carts: this.carts,
                        shippingOption,
                    },
                    { headers: { mode: "buy" } }
                );
                this.$store.dispatch("addToCart", carts);

                const _carts = carts.map(({ _id }) => _id);
                this.$store.dispatch("pickup", _carts);
                this.$router.push({ path: "/shop/order" });

                // this.fbq({ type: "AddToCart" });
                // this.wcs_do_cnv({ type: "3" });
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            } finally {
                this.loading = false;
            }
        },

        enlipleTracker() {
            let categories = [...this.product?.category?.parents, this.product?.category].filter((item) => item);
            /** @type {import('@/store/enliple-tracker').EnlipleTrackerCallMtmProps & import('@/store/enliple-tracker').EnlipleTrackerProductViewProps} */
            let props = {
                convType: "etc",
                productCode: this.product?.code || "",
                productName: this.product?.name || "",
                price: `${this.product?.price || ""}`,
                dcPrice: `${this.product?.salePrice || ""}`,
                soldOut: this.product?.sells ? "N" : "Y",
                imageUrl: this.product?.thumb?.url || "",
                secondImageUrl: this.product?.images?.[0]?.url || "",
                thirdImageUrl: this.product?.images?.[1]?.url || "",
                fourthImageUrl: this.product?.images?.[2]?.url || "",
                topCategory: categories?.[0]?.name || "",
                firstSubCategory: categories?.[1]?.name || "",
                secondSubCategory: categories?.[2]?.name || "",
                thirdSubCategory: categories?.[3]?.name || "",
            };
            this.callMtmOnProductView(props);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
</style>