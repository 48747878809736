<template>
    <client-page>
        <!-- <shop-product-visual image="/images/shop/sales/visual.jpg" title="SALES &amp; PROMOTIONS" :breadcrumbs="breadcrumbs"></shop-product-visual> -->

        <!-- <page-section>
            <v-container>
                <div class="tit mb-12px mb-md-16px font-tertiary">Don't miss anything and secure your favorite pieces</div>
                <p class="txt txt--sm mb-40px mb-md-80px">
                    Here you will find current offers at attractive prices - all first choice. <br />
                    Enjoy browsing!
                </p>
                <v-sheet max-width="980" class="mx-auto">
                    <v-row justify="center" align="center" class="row--lg">
                        <v-col cols="12" md="6">
                            <v-img src="/images/shop/sales/sales.jpg" />
                        </v-col>
                        <v-col cols="12" md="6">
                            <div class="txt txt--xs font-secondary spaced-text mb-12px mb-md-16px">24.06. - 31.07.2024</div>
                            <h2 class="tit font-tertiary font-weight-regular mb-14px mb-md-16px">Summer Sale 2024</h2>
                            <p class="txt txt--sm font-secondary">Discover attractive prices for porcelain crockery, glasses and cutlery. Save up to 50% on selected products</p>
                            <v-btn elevation="4" large tile class="white mt-18px mt-md-24px px-20px px-md-26px rounded-xs">
                                <span class="txt txt--xs txt--dark font-weight-medium">Shopping now!</span>
                                <v-img src="/images/icon/icon-arrow-right.svg" class="ml-8px" />
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-container>
        </page-section> -->

        <page-section>
            <v-container>
                <div class="product-section-title white">
                    <div class="tit font-tertiary text-center">All products</div>
                </div>
            </v-container>
        </page-section>

        <page-section class="page-section--last">
            <v-container>
                <product-list-filter />

                <v-row class="row--contents flex-xl-nowrap">
                    <v-col>
                        <div class="mb-20px">
                            <v-row align="center">
                                <v-col cols="12" md="auto" class="text-right">
                                    <div class="txt txt--lg txt--dark">{{ summary.totalCount.format() }} 개</div>
                                </v-col>
                                <v-spacer />
                                <v-col cols="12" md="auto">
                                    <v-row no-gutters align="center">
                                        <v-col cols="auto">
                                            <v-sheet width="96" class="txt txt--sm txt--dark">Sort By:</v-sheet>
                                        </v-col>
                                        <v-spacer />
                                        <v-col cols="auto">
                                            <product-list-sort-select />
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </div>

                        <!-- 상품 리스트 -->
                        <section class="product-lists">
                            <div class="product-lists-body">
                                <!-- <shop-product-items>
                                    <shop-product-item v-for="(product, index) in products" :key="index" :value="product" />
                                </shop-product-items> -->
                                <v-row class="row--lg">
                                    <template v-for="(product, index) in products">
                                        <v-col :key="index" cols="6" sm="4" lg="3">
                                            <shop-product-card :value="product" />
                                        </v-col>
                                    </template>
                                </v-row>

                                <div class="product-lists__pagination">
                                    <pagination-component :value="page" :count="pageCount" @input="(page) => $router.push({ query: { ...$route.query, page } })"></pagination-component>
                                </div>
                            </div>
                        </section>
                    </v-col>
                </v-row>
            </v-container>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
import { attrs_input } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapSubtitle from "@/components/client/dumb/tit-wrap-subtitle.vue";
import ShopProductItem from "@/components/client/shop/shop-product-item.vue";
import ShopProductItems from "@/components/client/shop/shop-product-items.vue";
import ProductListFilter from "@/components/client/shop/products/filter/product-list-filter.vue";
import PaginationComponent from "@/components/client/pagination-component.vue";
import ProductListSortSelect from "@/components/client/shop/products/list/product-list-sort-select.vue";
import ProductListCategories from "@/components/client/shop/products/list/product-list-categories.vue";

import ShopProductVisual from "@/components/client/shop/shop-product-visual.vue";
import ShopProductCard from "@/components/client/shop/shop-product-card.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        TitWrapSubtitle,
        ShopProductItem,
        ShopProductItems,
        ProductListFilter,
        PaginationComponent,
        ProductListSortSelect,
        ProductListCategories,
        ShopProductVisual,
        ShopProductCard,
    },
    metaInfo() {
        return this.seo;
    },
    data: () => ({
        products: [],

        limit: 24,
        summary: { totalCount: 0 },

        loading: false,

        attrs_input,

        breadcrumbs: [
            {
                text: "Products",
                disabled: false,
            },
            {
                text: "Sales and promotions",
                disabled: false,
            },
        ],
    }),
    computed: {
        ...mapState(["categories"]),
        categories_children() {
            return this.categories.flatMap(({ children }) => children);
        },
        categories_grandChildren() {
            return this.categories_children.flatMap(({ children }) => children);
        },
        categories_granderChildren() {
            return this.categories_grandChildren.flatMap(({ children }) => children);
        },
        category() {
            const categoryCode = this.$route.query.category;
            if (categoryCode) return this.getCategory(this.categories, categoryCode) ?? this.getCategory(this.categories_children, categoryCode) ?? this.getCategory(this.categories_grandChildren, categoryCode) ?? this.getCategory(this.categories_granderChildren, categoryCode);
        },
        seo() {
            if (this.category?.seoEnabled) return this.category?.seo;
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            let { ...query } = this.$route.query;

            delete query.category;
            query._category = this.category?._id;
            if (!query._category) delete query._category;

            return { ...query };
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        params() {
            this.init();
        },
    },
    methods: {
        async init() {
            this.products = [];
            this.summary.totalCount = 0;
            this.loading = false;

            this.search();
        },

        async search() {
            if (this.loading) return;
            else this.loading = true;

            try {
                let { skip, limit, params } = this;
                params = { ...params };
                var { summary, products } = await api.v1.shop.products.gets({
                    headers: { skip, limit },
                    params,
                });

                if (this.params?._category == params?._category) {
                    this.summary = summary;
                    this.products = products;
                }
            } finally {
                this.loading = false;
            }
        },
        getCategory(categories, categoryCode) {
            return categories.find(({ code }) => code == categoryCode);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    #contents {
        padding-top: 0;
    }
}
</style>